import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import Modal from "react-modal";
import Sidebar from "../components/Common/Sidebar";
import Header from "../components/Common/Header";
import bike_img from "../images/bike_img.png";
import placeholder_img from "../images/camera_img.svg";
import SurveyForm from "../components/Service/Surveyform";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
const SurveyFormContext = createContext();
export const useAddNewServiceSurveyForm = () => useContext(SurveyFormContext);

function AddNewService() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  React.useEffect(() => {
    // console.log("data=--_>> ",localStorage.getItem("vinnData"))
    if (!localStorage.getItem("vinnData")) {
      navigate("/vinn");
    } else {
    }
  }, []);
  const data = localStorage.getItem("vinnData");
  const profile_id = localStorage.getItem("profile_id");
  const parsed_data = JSON.parse(data);
  const db_data = parsed_data?.data;
  const [selectedprevMileage, setprevSelectedMileage] = useState("");
  const [selectedMileage, setSelectedMileage] = useState("");
  useEffect(() => {
    // Function to handle the second API call
    const fetchMaintenanceList = (factoryNamereq) => {
      console.log(
        "Fetching maintenance list with factoryNamereq:",
        factoryNamereq
      );
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/maintenance-lists/factory/${db_data?.Factory_name}/${factoryNamereq}/`
        )
        .then((res) => {
          setLoading(false);
          // getProductImages();
          getSpareParts();
          setMentainance(res?.data[0]);
          localStorage.setItem("bulletinData", JSON.stringify(res?.data[0]));
          const storedItem = localStorage.getItem("bulletinData");
          console.log("Stored bulletin data:", storedItem);

          if (res.data && res.data[0].mileage) {
            const mileageOptions = res.data[0].mileage.map((m) => ({
              value: m.Mileage,
              label: `${m.Mileage} Km`,
            }));
            setMileageOptions(mileageOptions);
          }

          setAvailable(true);
        })
        .catch((error) => {
          console.error("Error fetching maintenance lists:", error);
        });
    };

    // First API call
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/${db_data?.vin_code}/`
      )
      .then((response) => {
        console.log("Vehicle maintenance history response:", response.data);
        let factoryNamereq;

        if (!response.data || response.data.length === 0) {
          // Handle case where no vehicle maintenance history is found
          console.log("No vehicle maintenance history found.");
          setprevSelectedMileage(null); // or set to default value
          setFactoryName("1st");
          setLoading(false);
          factoryNamereq = "1st"; // Default to '1st' if no history
        } else {
          const historyLength = response.data.length;
          console.log("Vehicle maintenance history length:", historyLength);

          if (historyLength === 1) {
            factoryNamereq = "2nd";
            setFactoryName("2nd");
          } else if (historyLength === 2) {
            factoryNamereq = "3rd";
            setFactoryName("3rd");
          } else if (historyLength === 3) {
            factoryNamereq = "4th";
            setFactoryName("4th");
          } else if (historyLength === 4) {
            factoryNamereq = "5th";
            setFactoryName("5th");
          } else if (historyLength === 5) {
            factoryNamereq = "6th";
            setFactoryName("6th");
          } else if (historyLength === 6) {
            factoryNamereq = "7th";
            setFactoryName("7th");
          } else if (historyLength === 7) {
            factoryNamereq = "8th";
            setFactoryName("8th");
          } else if (historyLength === 8) {
            factoryNamereq = "9th";
            setFactoryName("9th");
          } else if (historyLength === 9) {
            factoryNamereq = "10th";
            setFactoryName("10th");
          } else {
            factoryNamereq = "0";
            setFactoryName("0"); // Optionally handle lengths greater than 10 differently
          }

          // Set previous selected mileage
          setprevSelectedMileage(response.data[0].next_mileage);
        }

        // Make the second API call with the determined factoryNamereq
        console.log(
          "Calling fetchMaintenanceList with factoryNamereq:",
          factoryNamereq
        );
        fetchMaintenanceList(factoryNamereq);
      })
      .catch((error) => {
        console.error("Error fetching vehicle maintenance history:", error);
      });
  }, [db_data?.vin_code, db_data?.Factory_name]);

  const product_id = db_data?.id;
  const dateStr = db_data?.date_of_manufacture;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  console.log("db_data+++++>> ", db_data);
  const image_url = `${db_data?.image}`;
  const [openSidebar, setOpenSidebar] = useState(false);

  const [MileageOptions, setMileageOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState({});
  const [requiredata, setrequiredata] = useState([]);
  const [comment, setComment] = useState("");
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [files, setFiles] = useState([null, null, null, null]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  const [mentainence, setMentainance] = useState({});
  const [available, setAvailable] = useState(false);

  const timers = useRef([]);
  const [services, setServices] = useState([]);
  const [fillvalue, setfillValue] = useState({});
  const [valvalue, setvalValue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const currentItem = services[currentIndex];
  ///////
  const [isYesChecked, setIsYesChecked] = useState({});
  const [isChecked, setIsChecked] = useState({});
  const [textValue, setTextValue] = useState({});

  const timerRef = useRef([]);
  const [spareParts, setSpareParts] = useState([]);
  const [timer, setTimer] = useState({});
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [timerValue, setTimerValue] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [factoryName, setFactoryName] = useState("");
  // useEffect(() => {
  //   console.log("selectedMileage changed:", selectedMileage);
  //   console.log("selectedMileage changed:xxxxxxxxxxxxxxxx", factoryName);
  //   if (selectedMileage) {
  //     console.log("Calling allAPICall()");
  //     allAPICall(); // Call your function here
  //   }
  // }, [selectedMileage]);
  // useEffect( ()=>{

  //     const res =  axios.get(
  //       // `${process.env.REACT_APP_API_URL}/product-service/create/`,
  //       `${process.env.REACT_APP_API_URL}/maintenance-lists/factory/${db_data?.Factory_name}/`,
  //     ).then((res)=>{
  //       setLoading(false)
  //       // if("message" in res?.data){
  //       //   setAvailable(false)
  //       // }
  //       // else{
  //         // setInterval(countUp, 1000);
  //         getProductImages()
  //         getSpareParts()
  //         setMentainance(res?.data[0])
  //         console.log("86756tjjgtut768",res)
  //         console.log("86756tjjgtut768000000000",res?.data[0])

  //         localStorage.setItem('bulletinData', JSON.stringify(res?.data[0]));
  //         const storedItem = localStorage.getItem('bulletinData');
  //         console.log("86756tjjgtut768",storedItem)

  //         if (res.data && res.data[0].mileage) {
  //           // Extract mileage data and transform it into option objects
  //           const mileageOptions = res.data[0].mileage.map(m => ({
  //             value: m.Mileage,
  //             label: `${m.Mileage} Km`
  //           }));

  //           setMileageOptions(mileageOptions); // Update your mileageOptions state
  //         }

  //         // const options=[]
  //         // for(let i=0;i<res?.data?.length;i++){
  //         //   options.push({ value: `${res?.data[i]?.Mileage}`, label: `${res?.data[i]?.Mileage} Km` })
  //         // }
  //         // setMileageOptions(options)

  //         setAvailable(true)
  //       // }
  //       console.log("res------> ",res);
  //    })

  //   },[])

  const handleClick = () => {
    setShowModal(true);
    // if (checked) {
    //   saveServicesSingle(index)
    // }
  };
  // const getProductImages = ()=>{
  //   const res =  axios.get(
  //     // `${process.env.REACT_APP_API_URL}/product-service/create/`,
  //     // `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`,
  //     `${process.env.REACT_APP_API_URL}/api/service_image/${db_data?.id}/`,
  //   ).then((res)=>{
  //     if (res?.data && res.data.length > 0) {
  //       const data = res.data[0];

  //       if (data.image1) {
  //         setSelectedFile1(`${process.env.REACT_APP_API_URL}/${data.image1}`);
  //       }

  //       if (data.image2) {
  //         setSelectedFile2(`${process.env.REACT_APP_API_URL}/${data.image2}`);
  //       }

  //       if (data.image3) {
  //         setSelectedFile3(`${process.env.REACT_APP_API_URL}/${data.image3}`);
  //       }

  //       if (data.image4) {
  //         setSelectedFile4(`${process.env.REACT_APP_API_URL}/${data.image4}`);
  //       }
  //     }

  //  })
  // }
  const handleConfirm = () => {
    // Handle confirm logic here
    setShowModal(false);
  };
  const handleCardClick = (cardId, item) => {
    if (selectedCards.includes(cardId)) {
      // If card is already selected, remove it from selectedCards
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
      setSelectedItems(selectedItems.filter((item) => item?.id !== cardId));
    } else {
      // If card is not selected, add it to selectedCards
      setSelectedCards([...selectedCards, cardId]);
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here
    setShowModal(false);
  };

  const handleImageClick = (ref) => {
    console.log("image pciker ", ref);
    ref.current.click();
  };

  const handleFileSelect1 = (event) => {
    setSelectedFile1(URL.createObjectURL(event.target.files[0]));
    files[0] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect2 = (event) => {
    setSelectedFile2(URL.createObjectURL(event.target.files[0]));
    files[1] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect3 = (event) => {
    setSelectedFile3(URL.createObjectURL(event.target.files[0]));
    files[2] = event.target.files[0];
    setFiles([...files]);
  };

  const handleFileSelect4 = (event) => {
    setSelectedFile4(URL.createObjectURL(event.target.files[0]));
    files[3] = event.target.files[0];
    setFiles([...files]);
  };

  const handleSetIsCheckedChange = (checked, index) => {
    isChecked[index] = checked;
    // let int;
    setIsChecked((prev) => ({ ...prev, [index]: checked }));

    // if (checked) {
    //   saveServicesSingle(index)
    // }
  };

  const handleTextChange = (event, index) => {
    setTextValue((prev) => ({ ...prev, [index]: event.target.value || "" }));
  };
  const handleFillChange = (event, index) => {
    setfillValue((prev) => ({ ...prev, [index]: event.target.value || "" }));
  };
  const handleValvalueChange = (event, index) => {
    setvalValue((prev) => ({ ...prev, [index]: event.target.value || "" }));
  };

  const startTimerController = (index) => {
    const alreadyStarted = timerRef.current[index];
    console.log("checkingHaDev startTimerController", index, alreadyStarted);

    if (!alreadyStarted) {
      timerRef.current[index] = setInterval(() => {
        timers.current[index] = (timers.current[index] || 0) + 1;

        setTimer((prev) => ({ ...prev, [index]: (prev[index] || 0) + 1 }));
      }, 1000);
    }
  };
  const endTimerController = (index) => {
    const alreadyStarted = timerRef.current[index];

    if (alreadyStarted) {
      const timerValue = timers.current[index];

      if (!timerValue) {
        timers.current[index] = 0;
      }

      clearInterval(timerRef.current[index]);
    }
  };

  const handleYesCheckboxToggle = (checked = false, index) => {
    setIsYesChecked((prev) => ({ ...prev, [index]: checked }));
    if (checked) startTimerController(index);
    else endTimerController(index);
  };

  console.log("checkingHaDev save services isChecked0000000000", isChecked);
  const saveServices = async (e) => {
    e.preventDefault();
    const data = [];
    console.log(
      "checkingHaDev save services data s_______77777777666666",
      isChecked
    );
    var time_sum = 0;
    for (const index in isChecked) {
      if (Object.hasOwnProperty.call(isChecked, index)) {
        console.log(
          "checkingHaDev save services for",
          index,
          isChecked[index],
          isYesChecked
        );
        const value = isChecked[index];

        if (value) {
          data.push({
            is_active: isYesChecked[index],
            comment: textValue[index] || "",
            executed: isChecked[index] || "",
            fill: fillvalue[index] || "",
            value: valvalue[index] ? parseInt(valvalue[index]) : "",
            product: product_id,
            name: services.data[index].Maintainencepoint_name,
            time_spent: timerRef.current[index],
            user: profile_id,
          });
          time_sum += timerRef.current[index];
        }
      }
    }

    console.log(
      "checkingHaDev save       7777777777777777 services time data ",
      time_sum,
      data
    );
    if (data.length == 0) {
      // alert("Services is empty!")
      return;
    } else {
      try {
        const res = await axios.post(
          // `${process.env.REACT_APP_API_URL}/product-service/create/`,
          `${process.env.REACT_APP_API_URL}/create_product_services/`,
          // `${process.env.REACT_APP_API_URL}/product-service/create/`,
          data
        );

        if (res) {
          console.log("response of services ", res);
        }
        var formdata = new FormData();
        formdata.append(
          "description",
          `${data.length} Maintenance Services has been created successfully `
        );
        formdata.append(
          "estonian_description",
          `${data.length} Hooldusteenused on edukalt loodud. `
        );
        formdata.append(
          "latvian_description",
          `${data.length} Apkopes pakalpojumi ir veiksmīgi izveidoti. `
        );
        formdata.append(
          "finnish_description",
          `${data.length} Huoltopalvelut on luotu onnistuneesti. `
        );
        formdata.append("historical_note", ``);
        formdata.append("vehicle", db_data?.id);
        formdata.append("owner_email", db_data?.orderer_email);
        formdata.append("plate_number", db_data?.plate_number);
        formdata.append("vin_code", db_data?.vin_code);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(
          `${process.env.REACT_APP_API_URL}/history/create/`,
          // "https://obaidullah1122.pythonanywhere.com/history/create/",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
        // alert(" history has been created")
        navigate("/vehicleData");
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  // for temp data
  // useEffect(() => {
  //   console.log("==============54=",isYesChecked, textValue, isChecked, fillvalue, valvalue);
  // }, [isYesChecked, textValue, isChecked, fillvalue, valvalue]);
  const handleTemporaryMaintenanceButtonClick = async () => {
    try {
      // First API call
      const firstResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get_temporary_mechanical_notes/${db_data.id}/`
      );
      if (firstResponse.data) {
        const firstData = firstResponse.data;
        console.log("Data from the first API call:", firstData);
        setComment(firstData?.note);
        setSelectedMileage(firstData?.selected_mileage);
        // setCurrentOption({
        //   value: firstData?.selected_mileage,
        //   label: `${firstData?.selected_mileage} Km`
        // })
        //   if (firstData && firstData.selected_mileage) {
        //     setCurrentOption({
        //         value: firstData.selected_mileage,
        //         label: `${firstData.selected_mileage} Km`
        //     });
        // } else {
        //     // Handle the case when selected_mileage is empty
        //     // You can set it to a default value or keep it undefined, based on your requirement
        //     setCurrentOption({
        //         value: '',  // or any default value you wish to set
        //         label: t("Select_the_mileage")  // This can be any default label
        //     });
        // }

        // Second API call
        const secondResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/product/${db_data.id}/`
        );
        if (secondResponse.data) {
          const secondData = secondResponse.data;
          setrequiredata(secondData);

          // Extract and update states
          setIsYesChecked(secondData.map((item) => item.is_active));
          setTextValue(secondData.map((item) => item.comment || ""));
          setIsChecked(secondData.map((item) => item.executed || false));
          setfillValue(secondData.map((item) => item.fill || ""));
          setvalValue(
            secondData.map((item) => (item.value ? parseInt(item.value) : null))
          );

          console.log("Data from the second API call:", secondData);
          console.log("Data from the second API call:", isYesChecked);
          // Additional actions...
        } else {
          // No data from second API call
          toast.error("No data found from the second API call", {
            /* toast options */
          });
        }
      } else {
        // No data from first API call
        toast.error("No data found from the first API call", {
          /* toast options */
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const posttempImgs = async () => {
    const data = new FormData();
    data.append("product", product_id);
    for (let i = 0; i < files.length; i++) {
      if (files[i] != null) {
        data.append(`image${i + 1}`, files[i]);
      }
    }
    if (files.length === 0) {
      // alert("Value is empty!");
      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/service-image/`,
          data
        );
        if (res) {
          // setSelectedFile1("");
          // setSelectedFile2("");
          // setSelectedFile3("");
          // setSelectedFile4("");
        }
        // alert("service Images has been saved");
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  const handletempSubmit = async (e) => {
    // e.preventDefault(); // Prevent default form submission behavior

    if (files.length > 0) {
      try {
        await savetempServices(); // No longer passing the event object
      } catch (error) {
        console.log("Error in saveServices:", error);
        // alert("Error: saveServices failed!");
        return;
      }

      try {
        await posttempImgs(e); // Assuming postImgs still needs the event object
      } catch (error) {
        console.log("Error in postImgs:", error);
        // alert("Error: postImgs failed!");
        return;
      }

      const post = {
        product: product_id,
        note: comment,
        selected_mileage: selectedMileage,
      };
      if (comment.length === 0) {
        alert("Mechanical comments are empty!");
        return;
      } else {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/temporary_mechanical_notes/create`,
            post
          );

          if (res) {
            setComment("");
          }

          // alert("Images and comments have been created");
          navigate("/vehicleData");
        } catch (e) {
          alert(e);
        }
      }
    } else {
      toast.error(`Please fill all fields`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const savetempServices = async () => {
    const data = [];
    console.log(
      "checkingHaDev save services data s_______77777777666666",
      isChecked
    );
    var time_sum = 0;

    for (const index in isChecked) {
      if (Object.hasOwnProperty.call(isChecked, index)) {
        console.log(
          "checkingHaDev save services for",
          index,
          isChecked[index],
          isYesChecked
        );
        const value = isChecked[index];

        if (value) {
          data.push({
            is_active: isYesChecked[index],
            comment: textValue[index] || "",
            executed: isChecked[index] || "",
            fill: fillvalue[index] || "",
            value: valvalue[index] ? parseInt(valvalue[index]) : "",
            product: product_id,
            name: services.data[index].Maintainencepoint_name,
            estonian_name: services.data[index].Maintainencepoint_name_Estonian,
            latvian_name: services.data[index].Maintainencepoint_name_Latvian,
            finnish_name: services.data[index].Maintainencepoint_name_Finnish,
            time_spent: timerRef.current[index],
            user: profile_id,
          });
          time_sum += timerRef.current[index];
        }
      }
    }

    console.log("checkingHaDev save services time data ", time_sum, data);

    if (data.length === 0) {
      // alert("Services is empty!");
      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/create`, // Updated API endpoint
          data
        );

        if (res) {
          console.log("response of services ", res);
        }
        // alert("Maintenance Services have been created successfully");
      } catch (error) {
        console.log("Error uploading file: ", error);
        throw error; // Propagate the error
      }
    }
  };

  //   const savetempServices = async (e) => {
  //     e.preventDefault();
  //     const data = [];
  //     console.log("checkingHaDev save services data s_______77777777666666", isChecked);
  //     var time_sum = 0;

  //     for (const index in isChecked) {
  //         if (Object.hasOwnProperty.call(isChecked, index)) {
  //             console.log(
  //                 "checkingHaDev save services for",
  //                 index,
  //                 isChecked[index],
  //                 isYesChecked
  //             );
  //             const value = isChecked[index];

  //             if (value) {
  //                 data.push({
  //                     is_active: isYesChecked[index],
  //                     comment: textValue[index] || "",
  //                     executed: isChecked[index] || "",
  //                     fill: fillvalue[index] || "",
  //                     value: valvalue[index] ? parseInt(valvalue[index]) : "",
  //                     product: product_id,
  //                     name: services.data[index].Maintainencepoint_name,
  //                     time_spent: timerRef.current[index],
  //                     user: profile_id,
  //                 });
  //                 time_sum += timerRef.current[index];
  //             }
  //         }
  //     }

  //     console.log("checkingHaDev save services time data ", time_sum, data);

  //     if (data.length === 0) {
  //         alert("Services is empty!");
  //         return;
  //     } else {
  //         try {
  //             const res = await axios.post(
  //                 `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/create`, // Updated API endpoint
  //                 data
  //             );

  //             if (res) {
  //                 console.log("response of services ", res);
  //             }
  //             alert("Maintenance Services have been created successfully");
  //             navigate("/vehicleData");
  //         } catch (error) {
  //             console.log("Error uploading file: ", error);
  //         }
  //     }
  // };

  //
  const postImgs = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("product", product_id);
    for (let i = 0; i < files.length; i++) {
      if (files[i] != null) {
        data.append(`image${i + 1}`, files[i]);
      }
    }
    if (files.length == 0) {
      // alert("Value is empty!")
      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/service-image/`,
          // `${process.env.REACT_APP_API_URL}/service-image/`,
          data
        );
        if (res) {
          // setSelectedFile1("")
          // setSelectedFile2("")
          // setSelectedFile3("")
          // setSelectedFile4("")
        }
        // alert("service Images  has been saved")
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  const cardData = async (e) => {
    e.preventDefault();
    if (!isChecked || !textValue || !isYesChecked) return;
    const post = {
      is_active: isYesChecked,
      comment: textValue,
      executed: isChecked,
      product: product_id,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/create/product-service/`,
      post
    );
    if (res.data) {
      setTextValue("");
      setIsYesChecked({});
      setIsChecked({});
    }
  };
  async function deleteTemporaryData(productId) {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/temporary_maintenance/delete/${productId}/`
      );
      console.log("Temporary Maintenance Deleted");

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/temporary_mechanical_notes/delete/${productId}/`
      );
      console.log("Temporary Mechanical Notes Deleted");
    } catch (error) {
      console.error("Error in deleting temporary data:", error);
      // alert('Error: Deletion of Temporary Data Failed!');
    }
  }
  const postVehicleMaintenanceHistory = async () => {
    const mileageIncrements = {
      ATV: 1000,
      SSV: 1000,
      UTV: 1000,
      MOTORCYCLE: 5000,
      "CHILDREN'S ATV": 50,
      "CHILDREN'S MOTORCYCLE": 50,
    };

    const firstmileageIncrements = {
      ATV: 1000,
      SSV: 1000,
      UTV: 1000,
      MOTORCYCLE: 5000,
      "CHILDREN'S ATV": 30,
      "CHILDREN'S MOTORCYCLE": 80,
    };

    const increment = mileageIncrements[db_data?.series] || 0;

    let nextMileage;
    if (!selectedprevMileage) {
      nextMileage = parseInt(firstmileageIncrements[db_data?.series]);
    } else {
      nextMileage = parseInt(selectedMileage, 10) + parseInt(increment, 10);
    }

    const vehicleMaintenanceData = {
      factory_name: db_data?.Factory_name,
      vinnumber: db_data?.vin_code,
      current_mileage: selectedMileage,
      next_mileage: nextMileage,
      date: new Date().toISOString().split("T")[0],
      next_mileage_jump: 15000,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/`,
        vehicleMaintenanceData
      );
      console.log("Vehicle maintenance history has been created");
    } catch (error) {
      console.error("Error in vehicle maintenance history creation:", error);
      // alert("Error: vehicle maintenance history creation failed!");
      throw error;
    }
  };

  // const postVehicleMaintenanceHistory = async () => {
  //   const mileageIncrements = {
  //     "ATV": 1000,
  //     "SSV": 1000,
  //     "UTV": 1000,
  //     "MOTORCYCLE": 15000,
  //     "CHILDREN'S ATV": 200,
  //     "CHILDREN'S MOTORCYCLE": 80,
  //   };

  //   // Get the increment value for the given series
  //   const increment = mileageIncrements[db_data?.series] || 0;

  //   const vehicleMaintenanceData = {
  //     factory_name: db_data?.Factory_name,
  //     vinnumber:  db_data?.vin_code,
  //     current_mileage: selectedMileage,
  //     next_mileage: parseInt(selectedMileage, 10) + parseInt(increment, 10),
  //     date: new Date().toISOString().split('T')[0],
  //     next_mileage_jump: 15000,
  //   };

  //   try {
  //     await axios.post(`${process.env.REACT_APP_API_URL}/api/vehicle-maintenance-history/`, vehicleMaintenanceData);
  //     console.log("Vehicle maintenance history has been created");
  //   } catch (error) {
  //     console.error("Error in vehicle maintenance history creation:", error);
  //     alert("Error: vehicle maintenance history creation failed!");
  //     throw error;
  //   }
  // };
  const handleSubmit = async (e) => {
    if (comment.length > 0 && files.length > 0) {
      try {
        await saveServices(e);
      } catch (error) {
        console.log("Error in saveServices:", error);
        // alert("Error: saveServices failed!");
        return;
      }
      try {
        await deleteTemporaryData(product_id);
      } catch (error) {
        console.error("Error in deleteTemporaryData:", error);
        // alert("Error: deleteTemporaryData failed!");
        return;
      }
      try {
        await postVehicleMaintenanceHistory();
      } catch (error) {
        console.error("Error in vehicle maintainance history:", error);
        // alert("Error: Error in vehicle maintainance history:");
        return;
      }

      try {
        await postImgs(e);
      } catch (error) {
        console.log("Error in postImgs:", error);
        // alert("Error: postImgs failed!");
        return;
      }

      await cardData(e);
      // await deleteTemporaryData(product_id);

      e.preventDefault();
      const post = { product: product_id, note: comment };
      if (comment.length == 0) {
        // alert("Mechenical comments is empty!")
        return;
      } else {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/mechanical-notes/create/`,
            post
          );

          var formdata = new FormData();
          formdata.append(
            "description",
            "Images and comments has been created"
          );
          formdata.append(
            "estonian_description",
            `Pildid ja kommentaarid on loodud. `
          );
          formdata.append(
            "latvian_description",
            `Attēli un komentāri ir izveidoti. `
          );
          formdata.append(
            "finnish_description",
            `Kuvat ja kommentit on luotu.`
          );
          formdata.append("historical_note", ``);
          formdata.append("vehicle", db_data?.id);
          formdata.append("owner_email", db_data?.orderer_email);
          formdata.append("plate_number", db_data?.plate_number);
          formdata.append("vin_code", db_data?.vin_code);

          var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };
          fetch(
            `${process.env.REACT_APP_API_URL}/history/create/`,
            // "https://obaidullah1122.pythonanywhere.com/history/create/",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));

          // alert("Images, comments and history has been created")
          navigate("/vehicleData");

          if (res) {
            setComment("");
          }
        } catch (e) {
          alert(e);
        }
      }
    } else {
      toast.error(`Please fill all Fields`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const getSpareParts = () => {
    const res = axios
      .get(
        // `${process.env.REACT_APP_API_URL}/product-service/create/`,
        // `${process.env.REACT_APP_API_URL}/api/newspareparts/${db_data?.sku}/`,
        `${process.env.REACT_APP_API_URL}/api/newspareparts/${db_data?.sku}/`
      )
      .then((res) => {
        if (res?.data && res?.data.length > 0) {
          setSpareParts(res?.data);
        }
      });
  };
  const allAPICall = async () => {
    if (selectedMileage) {
      try {
        const res = await axios.get(
          // `${process.env.REACT_APP_API_URL}/getallservices/`
          `${process.env.REACT_APP_API_URL}/api/maintenance-lists-point/factory/${db_data?.Factory_name}/${factoryName}/`
          // `${process.env.REACT_APP_API_URL}/getallservices/?sku_name=${db_data?.sku}&mileage=${selectedMileage}`
          // `${process.env.REACT_APP_API_URL}/api/maintenance-list-point/factory/${db_data?.Factory_name}/`
        );
        console.log(
          "444---------fggfgf-",
          `${process.env.REACT_APP_API_URL}/api/maintenance-lists/factory/${db_data?.Factory_name}/${factoryName}/`
        );
        setServices(res);
        console.log("cccc----------", services);
        toast.success("Checklist successfully populated below.");
      } catch (error) {
        toast.error("Something went wrong while fetching Checklist.");
      }
    }
  };
  const dataMileage = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/mileages/`);
    const options = [];
    for (let i = 0; i < res?.data?.length; i++) {
      options.push({
        value: `${res?.data[i]?.Mileage}`,
        label: `${res?.data[i]?.Mileage} Km`,
      });
    }
    setMileageOptions(options);
  };
  useEffect(() => {
    handleTemporaryMaintenanceButtonClick();
    dataMileage();
    // getProductImages()
    getSpareParts();
  }, []);
  console.log("------->>>>>>>>>>>>>>.--", services);

  const handleConfirmService = async (name, instruction) => {
    const res = await axios.post(
      "https://obaidullah1122.pythonanywhere.com/services/create/",
      {
        name: name,
        instructions: instruction,
        instruction_active: true,
      }
    );
    allAPICall();
  };

  console.log("services__services__services__services__", services);

  const surveyFormContextValues = {
    requiredata,
    selectedMileage: selectedMileage,
    comment: comment,
    data: services?.data || [],
    fillvalue,
    handleFillChange,
    valvalue,
    handleValvalueChange,
    handleYesCheckboxToggle,
    startTimerController,
    endTimerController,
    textValue,
    handleTextChange,
    handleSetIsCheckedChange,
  };
  const storedItem = localStorage.getItem("selectedLanguage");

  // Parsing the retrieved item as a JSON object
  const parsedItem = JSON.parse(storedItem);

  // Accessing the 'label' property of the parsed object
  const label = parsedItem.label;

  console.log("require", label);
  // const handleProceed = () => {
  //   if (!selectedMileage) {
  //     toast.error('Please enter mileage');
  //     return;
  //   }

  //   if (selectedMileage <= selectedprevMileage) {
  //     toast.error('Selected mileage must be greater than previous mileage');
  //     return;
  //   }

  //   console.log("Proceed button clicked");
  //   allAPICall();
  //   // Add your logic for the proceed action here
  // };
  const firstMaintenanceSchedule = {
    ATV: 300,
    SSV: 300,
    UTV: 300,
    MOTORCYCLE: 500,
    "CHILDREN'S ATV": 30,
    "CHILDREN'S MOTORCYCLE": 30, // in hours
  };

  const handleProceed = () => {
    if (!selectedMileage) {
      toast.error("Please enter mileage", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (!selectedprevMileage) {
      const category = db_data?.series;
      const series = "CF150 - CF650"; // This should be dynamically set based on the specific series from your data

      if (
        category === "ATV" &&
        selectedMileage < firstMaintenanceSchedule["ATV"]
      ) {
        toast.error("First maintenance for ATV should be 300 km or above", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      if (
        category === "SSV" &&
        selectedMileage < firstMaintenanceSchedule["SSV"]
      ) {
        toast.error("First maintenance for SSV should be 300 km or above", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      if (
        category === "UTV" &&
        selectedMileage < firstMaintenanceSchedule["UTV"]
      ) {
        toast.error("First maintenance for UTV should be 300 km or above", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      if (
        category === "MOTORCYCLE" &&
        selectedMileage < firstMaintenanceSchedule["MOTORCYCLE"]
      ) {
        toast.error(
          "First maintenance for MOTORCYCLE should be 500 km or above",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      if (
        category === "CHILDREN'S ATV" &&
        selectedMileage < firstMaintenanceSchedule["CHILDREN'S ATV"]
      ) {
        toast.error(
          "First maintenance for CHILDREN'S ATV should be 30 km or above",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      if (
        category === "CHILDREN'S MOTORCYCLE" &&
        selectedMileage < firstMaintenanceSchedule["CHILDREN'S MOTORCYCLE"]
      ) {
        toast.error(
          "First maintenance for CHILDREN'S MOTORCYCLE should be 30 hours or above",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }
    }

    if (selectedMileage <= selectedprevMileage) {
      toast.error(
        `Selected mileage must be greater than ${selectedprevMileage} mileage`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return;
    }

    console.log("Proceed button clicked");
    allAPICall();
    // Add your logic for the proceed action here
  };

  return !loading ? (
    <>
      <SurveyFormContext.Provider value={surveyFormContextValues}>
        <div className="flex">
          <Sidebar
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            onItemClick={handletempSubmit}
          />

          <Header setOpenSidebar={setOpenSidebar} />

          <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
            {/* ADD NEW SERVICE */}
            <div className="px-[26px] md:pl-9 xl:pl-[3.25rem] md:pr-10 pt-[22px] md:pt-[42px] xl:pt-14 pb-11 xl:pb-16">
              <div className="flex flex-col md:flex-row mb-[22px] md:mb-[30px] xl:mb-14">
                {/* LEFT */}
                <div className="w-full mb-10 md:mb-0 md:w-1/2 md:pt-3 xl:pt-12">
                  {/* IMAGE */}
                  <div>
                    <img className="mx-auto" src={image_url} alt="bike-img" />
                  </div>
                </div>

                {/* RIGHT */}
                <div className="w-full md:w-1/2 flex flex-col self-center">
                  <div className="md:pl-5 xl:pl-[30px]">
                    {/* BIKE NAME */}
                    <div className="mb-3 md:mb-1 xl:mb-[86px]">
                      <h3 className="bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold">
                        {db_data?.model_name}, {db_data?.year}, {db_data?.color}
                      </h3>
                    </div>

                    {/* SERVICE-KM */}
                    <div className="mb-3 md:mb-[30px] xl:mb-[83px]">
                      <h1 className=" text-blue text-f_16_l_20 md:text-f_22_l_28 xl:text-f_35_l_44 font-supremeBold">
                        {label && label.includes("Estonian")
                          ? mentainence?.Maintenance_list_name_estonian || ""
                          : label && label.includes("Finnish")
                            ? mentainence?.Maintenance_list_name_finnish || ""
                            : label && label.includes("Latvian")
                              ? mentainence?.Maintenance_list_name_latvian || ""
                              : label && label.includes("English")
                                ? mentainence?.Maintenance_list_name || ""
                                : ""}

                        {/* {mentainence?.Maintenance_list_name} */}
                      </h1>
                    </div>

                    {/* SERVICE DESCRIPTION */}
                    {/* <div>
                        <h4 className="text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4">
                        {t("Mileage_at_maintenance")} <span>

      

        <Dropdown className="custom-dropdown mt-5" options={MileageOptions} value={currentOption} onChange={(value)=>setSelectedMileage(value && value?.value?value.value:"")} placeholder={t("Select_the_mileage")} />
      </span>
      </h4>
                          
                        </div> */}
                    <div>
                      <h4 className="text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4">
                        {t("waranty_milage")}
                        <span>
                          <input
                            value={selectedMileage}
                            onChange={(event) =>
                              setSelectedMileage(event.target.value)
                            }
                            type="number"
                            class="rounded-lg px-3 py-2 bg-gray-900 w-24 h-8 border border-gray-400"
                          />
                        </span>{" "}
                        km
                      </h4>
                      <button
                        className="
    w-full 
    max-w-[80px]  /* Reduced width */
    md:max-w-[100px]  /* Reduced width */
    bg-gradient 
    rounded-[5px] 
    text-sm 
    md:text-base 
    lg:text-lg 
    font-supremeMedium
    flex
    items-center
    justify-center
    mb-5
  "
                        style={{ height: "30px", maxWidth: "100px" }} // Custom height and width
                        onClick={handleProceed}
                      >
                        Continue
                      </button>
                    </div>
                    <div>
                      <h4 className="text-f_16_l_20 md:text-f_18_l_23 xl:text-f_22_l_28 font-supremeBold text-white-100 mb-3 md:mb-1.5 xl:mb-4">
                        {t("Maintenance_description")}
                      </h4>
                      <p className="text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100">
                        {label && label.includes("Estonian")
                          ? mentainence?.maintenance_description_estonian || ""
                          : label && label.includes("Finnish")
                            ? mentainence?.maintenance_description_finnish || ""
                            : label && label.includes("Latvian")
                              ? mentainence?.maintenance_description_latvian || ""
                              : label && label.includes("English")
                                ? mentainence?.Maintainence_description || ""
                                : ""}
                      </p>
                    </div>
                    {/* <button
                    className="mt-5 p-4  rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium" onClick={allAPICall}
                    style={{ backgroundColor: '#181818' }}
                    >
                    <span style={{ fontSize: '24px', color:'#009bb4' }}>{t("next")}</span>
                  </button> */}
                  </div>
                </div>
              </div>

              {/* SPARE PARTS FOR SERVICE */}
              <div className="mb-10 md:mb-8 xl:mb-[51px]">
                {/* TITLE */}
                <div className="mb-6 md:mb-[26px]">
                  <h3 className="text-f_20_l_25 xl:text-f_22_l_28 text-white-100 font-supremeBold">
                    {t("Spare_parts_for_service")}
                  </h3>
                </div>

                {/* ITEMS LIST */}
                <div className="mb-10 md:mb-8 xl:mb-[51px]">
                  {/* TITLE */}

                  {/* ITEMS LIST */}

                  <div className="space-y-4 md:space-y-[27px] xl:space-y-8">
                    {/* ITEM */}

                    {selectedItems.map((item) => (
                      <>
                        <div className="bg-black-500 border-[0.5px] border-white-100 rounded-[10px] flex items-center px-4 md:pl-[34px] xl:pl-14 pt-3.5 xl:pt-[17px] pb-5">
                          {/* LEFT */}
                          <div className="w-[45%] md:w-[43.666667%] flex flex-col space-y-2 xl:space-y-4 uppercase">
                            <span className="text-blue text-f_16_l_20 xl:text-f_18_l_23">
                              SKU
                            </span>
                            <span className="text-f_14_l_18 md:text-f_13_l_16 xl:text-f_18_l_23">
                              {item?.product}
                            </span>
                          </div>

                          {/* RIGHT */}
                          <div className="flex flex-col space-y-2 xl:space-y-4 uppercase">
                            <span className="text-f_16_l_20 xl:text-f_18_l_23 text-blue">
                              NAME
                            </span>
                            <span className="text-f_14_l_18 md:text-f_13_l_16 xl:text-f_18_l_23">
                              {item?.part_name}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}

                    {/* <Sparepartmodal/> */}
                    <button
                      className="p-4  rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
                      onClick={handleClick}
                      style={{ backgroundColor: "#181818" }}
                    >
                      <span style={{ fontSize: "24px", color: "#009bb4" }}>
                        +
                      </span>
                    </button>

                    <Modal
                      isOpen={showModal}
                      onRequestClose={() => setShowModal(false)}
                      className="fixed inset-0 flex items-center justify-center z-50"
                      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                    >
                      <div className="bg-black-500 rounded-lg p-6">
                        <h1 className="text-xl font-bold mb-4">
                          Replaced parts
                        </h1>
                        <p className="bg-gradient-text mb-6">
                          you have to select replace parts
                        </p>
                        <div className="grid grid-cols-2 gap-4">
                          {spareParts.map((item, index) => (
                            <>
                              <div
                                className={`card bg-white rounded-lg p-4 ${selectedCards.includes(item?.id)
                                  ? "selected"
                                  : ""
                                  }`}
                                onClick={() => handleCardClick(item?.id, item)}
                              >
                                <h2 className="text-lg font-semibold">
                                  {item?.part_name}
                                </h2>
                                <p>{item?.product}</p>
                              </div>
                            </>
                          ))}

                          {/* Add more cards as needed */}
                        </div>
                        <div className="flex justify-end mt-6">
                          <button
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 mr-2"
                            onClick={handleConfirm}
                          >
                            Confirm
                          </button>
                          <button
                            className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <style>{`
              .card {
                /* Add your regular card styles here */
                border: 2px solid #ffffff; /* Set the default border color */
              }
              
              .card.selected {
                border-color: #009bb4; /* Set the border color for selected cards */
              }
            `}</style>
                  </div>
                </div>
              </div>

              {/* VEHICLE PHOTOS */}
              <div className="mb-[67px] md:mb-[41px] xl:mb-[90px]">
                {/* TITLE */}
                <div className="mb-6 md:mb-14 xl:mb-[43px]">
                  <h3 className="text-f_20_l_25 md:text-f_22_l_28 text-white-100 font-supremeBold mb-3 md:mb-2.5">
                    {t("Mileage_at_maintenance")}
                  </h3>
                  <p className=" text-f_14_l_22 md:text-f_16_l_25 xl:text-f_18_l_28 text-white-100">
                    {t("Vehicle_photos_desc")}
                  </p>
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-4 gap-5 md:gap-y-[27px] md:gap-x-[30px]">
                  {[
                    {
                      file: selectedFile1,
                      placeholder: "Addspeedometer",
                      ref: fileInputRef1,
                      onChange: handleFileSelect1,
                    },
                    {
                      file: selectedFile2,
                      placeholder: "addvincode",
                      ref: fileInputRef2,
                      onChange: handleFileSelect2,
                    },
                    {
                      file: selectedFile3,
                      placeholder: "add45front",
                      ref: fileInputRef3,
                      onChange: handleFileSelect3,
                    },
                    {
                      file: selectedFile4,
                      placeholder: "add45back",
                      ref: fileInputRef4,
                      onChange: handleFileSelect4,
                    },
                  ].map((image, index) => (
                    <div key={index}>
                      <div
                        className="w-full h-[156px] md:h-[196px] xl:h-[168px] flex justify-center items-center bg-black-500 rounded-[10px]"
                        style={{
                          backgroundImage: image.file
                            ? `url(${image.file})`
                            : "none",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        onClick={() => handleImageClick(image.ref)}
                      >
                        {!image.file && (
                          <div className="flex flex-col justify-center items-center cursor-pointer text-center">
                            <img src={placeholder_img} alt="add-img" />
                            <div
                              className="text-f_18_l_28 mt-3.5 break-all hyphens-auto"
                              style={{ wordBreak: "break-word" }}
                            >
                              {t(image.placeholder)}
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        ref={image.ref}
                        style={{ display: "none" }}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={image.onChange}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Maintenance service */}
              <div className="mb-[53px] md:mb-[61px] xl:mb-10">
                {/* Maintenance service TEXT */}
                <div className="flex flex-col xl:flex-row items-start xl:items-center justify-between mb-5 md:mb-[21px] xl:mb-8">
                  <h3 className="text-f_20_l_25 md:text-f_22_l_28 xl:text-f_26_l_33 text-white-100 font-supremeMedium mb-4 xl:mb-0">
                    {t("Maintainence_list_desc")}
                  </h3>
                  <div className="text-f_16_l_20 md:text-f_18_l_23 text-blue">
                    {t("Maintainence_list_desc_2")}
                  </div>
                </div>

                <SurveyForm />
              </div>

              {/* Mechanic comment */}
              <div className="mb-7">
                <h3 className="text-f_22_l_28 md:text-f_27_l_34 font-supremeBold mb-[25px] md:mb-5">
                  {t("Mechanic_comment")}
                </h3>

                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="h-[169px] border border-white-100 rounded-[10px] bg-transparent w-full"
                />
              </div>

              {/* SAVE SERVICE DATA BUTTON */}
              <button
                className="w-[212px] h-[52px] md:h-[62px] bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
                onClick={handleSubmit}
              >
                {t("Maintainence_button_text")}
              </button>
              <br />
              {/* <button
                    onClick={()=>{
                      handletempSubmit()
                    }}
                    className="p-4 mt-4 bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
                    >
                    Save Temporary Maintenance Data
                  </button> */}
              {/* {
    
      (requiredata && comment && selectedMileage && requiredata.length !== 0 && comment.length !== 0 && selectedMileage.length !== 0) ? (
           <button
      onClick={() => handletempSubmit()}
      className="p-4 mt-4 bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
  >
      Save Temporary Maintenance Data
  </button>
      ) : <button
      onClick={() => handletempSubmit()}
      className="p-4 mt-4 bg-gradient rounded-[10px] text-f_16_l_20 md:text-f_22_l_28 font-supremeMedium"
  >
      Save Temporary Maintenance Data
  </button>
  } */}
            </div>
          </main>
        </div>
      </SurveyFormContext.Provider>
    </>
  ) : (
    <>
      <div className="flex">
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

        <Header setOpenSidebar={setOpenSidebar} />

        <main className="md:ml-[16.25rem] xl:ml-[21.125rem] pt-[62px] md:pt-[6.5625rem] w-full">
          <h3 className="bg-gradient-text text-f_19_l_24 md:text-f_22_l_28 xl:text-f_27_l_34 text-left font-supremeBold">
            No Maintainence found
          </h3>
        </main>
      </div>
    </>
  );
}

export default AddNewService;
